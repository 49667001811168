(function() {
    'use strict';

    angular
        .module('mlcccApp')
        .controller('MlcClassDetailController', MlcClassDetailController);

    MlcClassDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'loginUser', 'MlcClass', 'ClassStatus', 'ClassTime', 'Teacher', 'ClassRoom', 'SchoolTerm'];

    function MlcClassDetailController($scope, $rootScope, $stateParams, previousState, entity, loginUser, MlcClass, ClassStatus, ClassTime, Teacher, ClassRoom, SchoolTerm) {
        var vm = this;

        vm.mlcClass = entity;
        vm.previousState = previousState.name;
        vm.isAdmin = loginUser.authorities.includes('ROLE_ADMIN');

        if (vm.mlcClass.classRoom && vm.mlcClass.classRoom.roomNumber < 100){  // online classes
            if(vm.isAdmin){
                vm.mlcClass.classRoom.roomNumber = vm.mlcClass.classRoom.description;
            } else {
                vm.mlcClass.classRoom.roomNumber = 'Online';
            }
        }

        var unsubscribe = $rootScope.$on('mlcccApp:mlcClassUpdate', function(event, result) {
            vm.mlcClass = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
