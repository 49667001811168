(function() {
    'use strict';

    angular
        .module('mlcccApp')
        .controller('RegistrationController', RegistrationController);

    RegistrationController.$inject = ['$state', 'loginUser', 'Registration', 'SchoolTerm','ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function RegistrationController($state, loginUser, Registration, SchoolTerm, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.confirm = confirm;
        vm.searchRegistration = searchRegistration;
        vm.schoolTerms = loadSchoolTerms;
        vm.loadAll = loadAll;
        vm.schoolTerm = pagingParams.schoolTerm;
        vm.showClassRoom = !loginUser.authorities.includes('ROLE_ADMIN');

        loadSchoolTerms();

        function loadAll (schoolTermId) {
            Registration.query({
                schoolTermId: schoolTermId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.registrations = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                schoolTerm: vm.schoolTerm,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });

        }

        function confirm(data){
            data.status = 'CONFIRMED';
            Registration.update(data, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('mlcccApp:registrationUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function searchRegistration(searchTerm){
            if(vm.schoolTerm != null){
                Registration.query({
                    schoolTermId: vm.schoolTerm.id,
                    param: 'registrationsForStudent:' + vm.searchTerm
                }, onSuccess, onError);
            } else {
                Registration.query({
                    param: 'registrationsForStudent:' + vm.searchTerm
                }, onSuccess, onError);
            }
        }

        function loadSchoolTerms () {
            SchoolTerm.query(function(result) {
                vm.schoolTerms = result;
                vm.searchQuery = null;
                //vm.schoolTerm = vm.schoolTerms[0];
                if(vm.schoolTerm){
                    loadAll(vm.schoolTerm.id);
                } else {
                    loadAll();
                }
            });
        }
    }
})();
