(function() {
    'use strict';

    angular
        .module('mlcccApp')
        .controller('PaymentController', PaymentController);

    PaymentController.$inject = ['Payment', 'SchoolTerm', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants'];

    function PaymentController(Payment, SchoolTerm, ParseLinks, AlertService, $state, pagingParams, paginationConstants) {

        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.payments = [];
        vm.searchPayment = searchPayment;
        vm.schoolTerm = pagingParams.schoolTerm;

        vm.loadAll = loadAll;

        if($state.params.invoiceId){
            getPaymentsByInvoiceId($state.params.invoiceId);
        } else {
            loadSchoolTerms();
        }

        function loadAll(schoolTermId) {
            Payment.query({
                schoolTermId: schoolTermId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: 'id,desc'
            }, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.payments = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function transition() {
            $state.transitionTo($state.$current, {
                schoolTerm: vm.schoolTerm,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function searchPayment(searchTerm){
            Payment.query({param: vm.searchTerm}, onSuccess);
        }

        function getPaymentsByInvoiceId(invoiceId){
            Payment.query({invoiceId: invoiceId}, onSuccess);
        }

        function loadSchoolTerms () {
            SchoolTerm.query(function(result) {
                vm.schoolTerms = result;
                vm.searchQuery = null;
                if(vm.schoolTerm) {
                    loadAll(vm.schoolTerm.id);
                } else {
                    loadAll();
                }
            });
        }
    }
})();
