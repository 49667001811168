(function() {
    'use strict';

    angular
        .module('mlcccApp')
        .controller('MlcClassRegistrationsController', MlcClassRegistrationsController);

    MlcClassRegistrationsController.$inject = ['$state','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Registration', 'Print'];

    function MlcClassRegistrationsController ($state, $timeout, $scope, $stateParams, $uibModalInstance, entity, Registration, Print) {
        var vm = this;

        vm.registrations = entity;
        vm.clear = clear;
        vm.print = print;
        vm.emailParents = email;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function print(className){
            var printContents = document.getElementById('print-section '+className).innerHTML;
            Print.print(className, printContents);
        }

        function email(className){
            var parents = [];
            vm.registrations.forEach(function(registration){
                parents.push(registration.invoice.user.email);
            });

            /*
            vm.registrations.forEach(function(registration) {
                if(mlcClass.name == className){
                    mlcClass.students.forEach(function(student){
                        student.associatedAccounts.forEach(function(account){
                            if(account.primaryContact && account.email){
                                parents.push(account.email);
                            }
                        });
                    });
                }
            })
            */
            $state.params.to = 'principals@mlccc.org';
            $state.params.subject = "MLCCC Admin on behalf of " + className;
            //$state.params.cc = vm.account.email;
            $state.params.bcc = parents;
            $state.go('mlc-class.registrations.email')
        }
    }
})();
